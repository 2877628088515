// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-narative-gatsby-theme-novela-templates-articles-template-tsx": () => import("./../src/@narative/gatsby-theme-novela/templates/articles.template.tsx" /* webpackChunkName: "component---src-narative-gatsby-theme-novela-templates-articles-template-tsx" */),
  "component---src-narative-gatsby-theme-novela-templates-article-template-tsx": () => import("./../src/@narative/gatsby-theme-novela/templates/article.template.tsx" /* webpackChunkName: "component---src-narative-gatsby-theme-novela-templates-article-template-tsx" */),
  "component---src-narative-gatsby-theme-novela-templates-author-template-tsx": () => import("./../src/@narative/gatsby-theme-novela/templates/author.template.tsx" /* webpackChunkName: "component---src-narative-gatsby-theme-novela-templates-author-template-tsx" */),
  "component---src-narative-gatsby-theme-novela-templates-activities-template-tsx": () => import("./../src/@narative/gatsby-theme-novela/templates/activities.template.tsx" /* webpackChunkName: "component---src-narative-gatsby-theme-novela-templates-activities-template-tsx" */),
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */)
}

